import {Pipe, PipeTransform} from '@angular/core';
import {format, parseISO} from 'date-fns';
import {KnowledgeSection} from './entity/http-result';
import {environment} from "../environments/environment";
import {UserProfile} from "./entity/http-vo";
import {byteVolToUnitVol} from "./utils/byteunit-utils";
import {currencyFen2Yuan} from "./utils/currency-utils";


@Pipe({name: 'yyyyMMddHHmmssTimeFormat', standalone: true})
export class YYYYMMddHHmmssTimeFormatPipe implements PipeTransform {

  transform(createdTime: string): string {
    if (createdTime) {
      const date = parseISO(createdTime);
      return format(date, "yyyy-MM-dd HH:mm:ss");
    }
    return ""
  }
}

@Pipe({name: 'humanTimeFormat', standalone: true})
export class HumanTimeFormatPipe implements PipeTransform {

  constructor(private updatedTimeFormat: UpdatedTimeFormatPipe) {
  }

  transform(updatedTime: string | undefined, createdTime: string): string {
    if (updatedTime) {
      return this.updatedTimeFormat.transform(updatedTime)
    }
    return this.updatedTimeFormat.transform(createdTime)
  }
}

@Pipe({name: 'updatedTimeFormat', standalone: true})
export class UpdatedTimeFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    const date = parseISO(value);
    const today = new Date();

    if (today.getFullYear() === date.getFullYear()) {
      // 同一年
      if (today.getMonth() === date.getMonth()) {
        // 同月
        if (today.getDate() === date.getDate()) {
          // 同一天
          const hm = format(date, "HH:mm");
          return `今天 ${hm}`;
        } else if (today.getDate() === date.getDate() + 1) {
          // 昨天
          const hm = format(date, "HH:mm");
          return `昨天 ${hm}`;
        } else if (today.getDate() === date.getDate() - 1) {
          // 明天
          const hm = format(date, "HH:mm");
          return `明天 ${hm}`;
        } else {
          return format(date, "MM-dd HH:mm");
        }
      } else {
        // 不同月
        return format(date, "MM-dd HH:mm");
      }
    } else {
      // 不同年
      return format(date, "yyyy-MM-dd");
    }

  }
}


@Pipe({name: 'packTypeEnum', standalone: true})
export class PackTypeEnumPipe implements PipeTransform {
  transform(type: number): string {
    let name = "";
    switch (type) {
      case 1:
        name = "原创";
        break;
      case 2:
        name = "购买";
        break;
    }

    return name;
  }
}


@Pipe({name: 'knowledgeSectionTree', standalone: true})
export class KnowledgeSectionTreePipe implements PipeTransform {
  transform(sections: Array<KnowledgeSection>): Array<KnowledgeSection> {

    if (sections.length < 1) {
      return [];
    }
    const result: Array<KnowledgeSection> = [];

    const rootNodes: Array<KnowledgeSectionTreeNode> = [];
    const leafNodes: Array<KnowledgeSectionTreeNode> = [];
    for (const section of sections) {
      if (section.parentId) {
        leafNodes.push(new KnowledgeSectionTreeNode(section));
      } else {
        // parentId 为空的是根节点
        rootNodes.push(new KnowledgeSectionTreeNode(section));
      }
    }

    rootNodes.sort((a, b) => a.nodeValue.idx - b.nodeValue.idx);

    for (const rootNode of rootNodes) {
      this.addChildrenNode(rootNode, leafNodes);
    }

    this.treeNodesToList(result, rootNodes);

    return result;
  }

  private addChildrenNode(parentNode: KnowledgeSectionTreeNode, restNodes: Array<KnowledgeSectionTreeNode>): void {
    const missNodes: Array<KnowledgeSectionTreeNode> = [];
    const hitNodes: Array<KnowledgeSectionTreeNode> = [];
    for (const childNode of restNodes) {
      if (childNode.nodeValue.parentId == parentNode.nodeValue.id) {

        if (parentNode.nodeValue.parentIdxName) {
          childNode.nodeValue.parentIdxName = `${parentNode.nodeValue.parentIdxName}${parentNode.nodeValue.idx}.`;
        } else {
          childNode.nodeValue.parentIdxName = `${parentNode.nodeValue.idx}.`;
        }

        hitNodes.push(childNode);
      } else {
        missNodes.push(childNode);
      }
    }

    if (hitNodes.length > 0) {

      parentNode.children = hitNodes.sort((a, b) => a.nodeValue.idx - b.nodeValue.idx);

      for (const nextGenParent of parentNode.children) {
        this.addChildrenNode(nextGenParent, missNodes);
      }
    }
  }

  private treeNodesToList(platList: Array<KnowledgeSection>,
                          treeNodes: Array<KnowledgeSectionTreeNode>) {
    for (const treeNode of treeNodes) {
      platList.push(treeNode.nodeValue);

      this.treeNodesToList(platList, treeNode.children);
    }
  }
}

class KnowledgeSectionTreeNode {
  nodeValue: KnowledgeSection;
  children: Array<KnowledgeSectionTreeNode>;

  constructor(nodeValue: KnowledgeSection, children?: Array<KnowledgeSectionTreeNode>) {
    this.nodeValue = nodeValue;
    if (children) {
      this.children = children!;
    } else {
      this.children = [];
    }
  }
}


@Pipe({name: 'levelIndent', standalone: true})
export class LevelIndentPipe implements PipeTransform {
  transform(lvl: number, step: number, styleName: string, unit: string): any {
    // lvl 从0开始的
    const val = lvl * step;
    return {
      [styleName]: val + unit
    };
  }
}

@Pipe({name: 'sectionPrefixName', standalone: true})
export class SectionPrefixNamePipe implements PipeTransform {
  transform(section: KnowledgeSection): any {
    if (section.level == 0) {
      // level是从0开始的
      return `${section.idx}. ${section.name}`;
    } else {
      return `${section.parentIdxName}${section.idx}、 ${section.name}`;
    }

  }
}


@Pipe({name: 'reviewOffsetTimeFormat', standalone: true})
export class ReviewOffsetTimeFormatPipe implements PipeTransform {
  transform(offsetMinutes: number, punctual: boolean): string {
    if (punctual) {
      return '准时';
    } else {
      let delayPrefix;
      if (offsetMinutes < 0) {
        delayPrefix = '拖延了';
      } else {
        delayPrefix = '提前了';
      }

      // 这里的拖延时间，显示的是截止到最后复习时间之后拖延了多久
      // 而最后复习时间是12小时以内完成都可以，所以这里的时间offsetMinutes需要减去12小时，才是用户超过了最后复习时间之后拖延了多久
      const offset = Math.abs(Math.abs(offsetMinutes) - (12 * 60));
      if (offset < 60) {
        return `${delayPrefix}${offset}分钟`;
      } else if (offset < 24 * 60) {
        return `${delayPrefix}${Math.floor(offset / 60)}小时`;
      } else {
        return `${delayPrefix}${Math.floor(offset / 60 / 24)}天`;
      }

    }
  }
}

@Pipe({name: 'publicResUrl'})
export class PublicResUrlPipe implements PipeTransform {
  transform(res: string): any {
    if (res) {
      return `${environment.BUSHEZHOUYE_API}/v2/public-res?resName=${res}`;
    }
  }
}


@Pipe({name: 'dateTimeISO', standalone: true})
export class DateTimeISOPipe implements PipeTransform {
  // 2022-02-08T09:32:04.546811+08:00
  transform(dateTime: string | undefined): string {
    if (dateTime) {
      const date = parseISO(dateTime);
      return format(date, 'yyyy-MM-dd HH:mm:ss');
    } else {
      return '';
    }
  }
}

@Pipe({name: 'vipLevelName', standalone: true})
export class VipLevelNamePipe implements PipeTransform {
  transform(userProfile?: UserProfile): string {
    if (userProfile && userProfile.vipLevelName) {
      return userProfile.vipLevelName;
    } else {
      return '免费会员';
    }
  }
}


@Pipe({name: 'avatarUrl', standalone: true})
export class AvatarUrlPipe implements PipeTransform {
  transform(userProfile?: UserProfile): string {
    if (userProfile && userProfile.avatar) {
      return `${environment.BUSHEZHOUYE_API}/v2/public-res?resName=${userProfile.avatar}`;
    } else {
      return "assets/default-avatar.svg";
    }
  }
}


@Pipe({name: 'unitVolume', standalone: true})
export class UnitVolumePipe implements PipeTransform {
  transform(vol?: number): string {
    if (vol !== null && vol !== undefined) {
      const res = byteVolToUnitVol(vol);
      var unitVol: string = '';
      switch (res[1]) {
        case 0:
          unitVol = `${res[0]} B`;
          break;
        case 1:
          unitVol = `${res[0]} KB`;
          break;
        case 2:
          unitVol = `${res[0]} MB`;
          break;
        case 3:
          unitVol = `${res[0]} GB`;
          break;
        case 4:
          unitVol = `${res[0]} TB`;
          break;
      }

      return unitVol;
    }

    return "";
  }
}


@Pipe({name: 'fenToYuan', standalone: true})
export class FenToYuanPipe implements PipeTransform {
  transform(fen: number | undefined | null): number {
    return currencyFen2Yuan(fen);
  }
}


@Pipe({name: 'contentTypeIcon', standalone: true})
export class ContentTypeIconPipe implements PipeTransform {
  transform(contentType: number): string {
    let icon = ""
    switch (contentType) {
      case 1:
        icon = 'file-word'
        break;
      case 2:
        icon = 'file-pdf'
        break;
      case 3:
        icon = 'file-image'
        break;
      case 4:
        icon = 'file-text'
        break;
    }

    return icon;
  }
}
