export function currencyFen2Yuan(fen: number | undefined | null): number {
  if (fen !== undefined && fen !== null) {
    return fen / 100;
  } else {
    return NaN;
  }
}

export function currencyYuan2Yuan(yuan: number | undefined | null): number {
  if (yuan !== undefined && yuan !== null) {
    return yuan * 100;
  } else {
    return NaN;
  }
}

export function isNotNumber(val: any): boolean {
  if (val == null || val == undefined) {
    return true;
  } else {
    if (typeof val == 'string' && val.trim() == "") {
      return true;
    } else {
      return isNaN(val);
    }
  }
}


export function isNumber(val: any): boolean {
  return !isNotNumber(val);
}
