export function byteVolToUnitVol(byteSize: number): number[] {
  const kb = Math.pow(1024, 1); // KB
  const mb = Math.pow(1024, 2); // MB
  const gb = Math.pow(1024, 3); // GB
  const tb = Math.pow(1024, 4); // TB

  if (byteSize >= tb) {
    return [parseFloat((byteSize / tb).toFixed(1)), 4];
  } else if (byteSize >= gb) {
    return [parseFloat((byteSize / gb).toFixed(1)), 3];
  } else if (byteSize >= mb) {
    return [parseFloat((byteSize / mb).toFixed(1)), 2];
  } else if (byteSize >= kb) {
    return [parseFloat((byteSize / kb).toFixed(1)), 1];
  } else {
    return [parseFloat((byteSize).toFixed(1)), 0];
  }
}

export function unitVolToByteVol(unitVol: number, unit: number): number {
  return unitVol * Math.pow(1024, unit);
}
